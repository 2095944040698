<template>
  <div class="kpi-row">
    <div
      class="kpi-card kpi-card-light"
      style="padding: 16px"
    >
      <div class="d-flex flex-row justify-content-between">
        <div class="buttons my-0">
          <div
            v-for="item in header"
            :key="item.value"
            class="button sm"
            :class="active === item.value ? 'primary' : 'transparent-dark'"
            @click="() => {
              setActive(item.value)
            }"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="buttons my-0">
          <div
            id="calendar1"
            class="button sm outlined"
          >
            <b-icon icon="calendar3" />
            {{ date1 }} -
            {{ date2 }}
          </div>
        </div>
      </div>
      <b-row
        v-if="stats"
        style="margin-top: 32px;"
      >
        <b-col
          sm="12"
          lg="6"
        >
          <div
            class="d-flex  justify-content-start align-items-center"
            style="height: 280px;  border-radius: 4px; margin-bottom: 16px"
          >
            <div
              v-if="stats.big + stats.pme + stats.micro > 0"
              class="align-self-start d-flex align-items-center justify-content-center"
              :style="`height: 280px;
                  width: 280px;
                  border-radius: 50%;
                  background: conic-gradient(#FFB703 ${(isNaN((stats.big * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.big * 100)/(stats.big + stats.pme + stats.micro))}%,#00ADEE ${(isNaN((stats.big * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.big * 100)/(stats.big + stats.pme + stats.micro))}%${(isNaN((stats.big * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.big * 100)/(stats.big + stats.pme + stats.micro))+(isNaN((stats.pme * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.pme * 100)/(stats.big + stats.pme + stats.micro))}%,#99DEF8 ${(isNaN((stats.big * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.big * 100)/(stats.big + stats.pme + stats.micro))+(isNaN((stats.pme * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.pme * 100)/(stats.big + stats.pme + stats.micro))}%${(isNaN((stats.big * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.big * 100)/(stats.big + stats.pme + stats.micro))+(isNaN((stats.pme * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.pme * 100)/(stats.big + stats.pme + stats.micro))+(isNaN((stats.micro * 100)/(stats.big + stats.pme + stats.micro)) ? 0 : (stats.micro * 100)/(stats.big + stats.pme + stats.micro))}%)`"
            >
              <div
                style="background-color: white; border-radius: 50%; height: 214px; width: 214px"
                class="d-flex align-items-center justify-content-center flex-column"
              >
                <div style="font-size: 48px; font-weight: 600; line-height: 48px">
                  {{ stats.big + stats.pme + stats.micro }}
                </div>
                <div
                  style="font-size: 14px; font-weight: 400"
                  class="text-dark-grey"
                >
                  Empresas
                </div>
              </div>
            </div>
            <div
              v-else
              class="align-self-start d-flex align-items-center justify-content-center"
              :style="`height: 280px;
                  width: 280px;
                  border-radius: 50%;
                  background: conic-gradient(#F1F1F1 0% 100%)`"
            >
              <div
                style="background-color: white; border-radius: 50%; height: 214px; width: 214px"
                class="d-flex align-items-center justify-content-center flex-column"
              >
                <div style="font-size: 48px; font-weight: 600; line-height: 48px">
                  {{ stats.big + stats.pme + stats.micro }}
                </div>
                <div
                  style="font-size: 14px; font-weight: 400"
                  class="text-dark-grey"
                >
                  Empresas
                </div>
              </div>
            </div>
            <div
              class="ml-4 d-flex flex-column"
              style="height: 100%; justify-content: space-evenly"
            >
              <div class="d-flex align-items-baseline">
                <div
                  style="width: 10px; height: 10px; background-color: #FFB703"
                  class="mr-4"
                />
                <div>
                  <div style="font-weight: 600">
                    Grande
                  </div>
                  <div>{{ !isNaN(((stats.big * 100)/(stats.big + stats.pme + stats.micro)).toFixed(2)) ? ((stats.big * 100)/(stats.big + stats.pme + stats.micro)).toFixed(2) : 0 }}%|{{ stats.big }}</div>
                </div>
              </div>
              <div class="d-flex align-items-baseline">
                <div
                  style="width: 10px; height: 10px; background-color: #00ADEE"
                  class="mr-4"
                />
                <div>
                  <div style="font-weight: 600">
                    PME
                  </div>
                  <div>{{ !isNaN(((stats.pme * 100)/(stats.big + stats.pme + stats.micro)).toFixed(2)) ? ((stats.pme * 100)/(stats.big + stats.pme + stats.micro)).toFixed(2) : 0 }}%|{{ stats.pme }}</div>
                </div>
              </div>
              <div class="d-flex align-items-baseline">
                <div
                  style="width: 10px; height: 10px; background-color: #99DEF8"
                  class="mr-4"
                />
                <div>
                  <div style="font-weight: 600">
                    Micro
                  </div>
                  <div>{{ !isNaN(((stats.micro * 100)/(stats.big + stats.pme + stats.micro)).toFixed(2)) ? ((stats.micro * 100)/(stats.big + stats.pme + stats.micro)).toFixed(2) : 0 }}%|{{ stats.micro }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <b-row>
            <b-col cols="12">
              <div
                style="background-color: #4C6F7C; height: 59px; width: 100%; border-radius: 4px; margin-bottom: 16px"
                class="d-flex flex-row align-items-center p-4 s2f-companies"
              >
                <div
                  class="text-white"
                  style="font-weight: 600"
                >
                  Empresas Shift2Future
                </div>
                <div
                  class="text-white ml-4"
                  style="font-size: 32px"
                >
                  {{ stats.companies_s2f }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <div
                class="d-flex   justify-content-between"
                style="height: 100%; border-radius: 4px; background-color: #FEEFEC; padding: 16px; padding-left: 20px; padding-right: 20px"
              >
                <div style="margin-left: -19px; margin-top: 60px;  overflow: hidden; position: absolute">
                  <b-icon
                    shift-h="-2"
                    icon="ui-checks"
                    font-scale="10"
                    style="opacity: 0.1; color: #000"
                  />
                </div>
                <div
                  class="align-self-start"
                  style="width: 50%; font-weight: 600"
                >
                  Questionários Iniciados
                </div>
                <div
                  class="align-self-end text-seconary"
                  style="font-size: 64px"
                >
                  {{ stats.quizzes }}
                </div>
              </div>
            </b-col>
            <b-col
              sm="12"
              lg="6"
            >
              <div
                class="d-flex justify-content-between"
                style="height: 192px;  border-radius: 4px 4px 0 0; background-color: #00ADEE10; padding: 16px; padding-left: 20px; padding-right: 20px"
              >
                <div style="margin-left: -19px; margin-top: 60px;  overflow: hidden; position: absolute">
                  <b-icon
                    shift-h="-4"
                    icon="file-earmark-text"
                    font-scale="8"
                    style="opacity: 0.2; color: #00455F"
                  />
                </div>
                <div
                  class="align-self-start"
                  style="width: 50%; font-weight: 600"
                >
                  Relatórios Finais Submetidos
                </div>
                <div
                  class="align-self-end text-seconary"
                  style="font-size: 64px"
                >
                  {{ stats.submitted }}
                </div>
              </div>
              <div
                class="d-flex flex-column align-items-start"
                style="background-color: #78AABE; border-radius: 0 0 4px 4px;  padding: 12px 16px 12px 16px; min-height: 72px"
              >
                <div
                  style="font-weight: 600"
                  class="text-white"
                >
                  Grau de maturidade médio global
                </div>
                <div
                  style="font-weight: 600; font-size: 18px;"
                  class="text-white"
                >
                  {{ stats.media.toFixed(2) }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <calendar-pick
      target="calendar1"
      :date1="date1"
      :set-date1="(value) => {
        setDate1(value)
      }"
      :date2="date2"
      :set-date2="(value) => {
        setDate2(value)

      }"
      :search="search"
    />

  <!--   <b-popover
      ref="popover1"
      target="calendar1"
      triggers="hover focus click"
      placement="bottom"
    >
      Desde:
      <b-form-datepicker
        :value="date1.split('/').reverse().join('-')"
        label-help=""
        boundary="window"
        dropleft
        placeholder="00/00/0000"
        locale="pt"
        label-no-date-selected="Selecionar uma data"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        v-bind="labels"
        :max="date2.split('/').reverse().join('-')"
        @input="(value) => {
          setDate1(value.split('-').reverse().join('/'))
        }"
      />

      Até:
      <b-form-datepicker
        :value="date2.split('/').reverse().join('-')"
        label-help=""
        boundary="window"
        dropleft
        placeholder="00/00/0000"
        locale="pt"
        label-no-date-selected="Selecionar uma data"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        v-bind="labels"
        :min="date1.split('/').reverse().join('-')"
        @input="(value) => {
          setDate2(value.split('-').reverse().join('/'))
        }"
      />
      <div class="buttons my-0 mt-2">
        <div
          class="button sm primary text-center"
          style="flex: 1"
          @click="() => {
            $refs.popover1.$emit('close')
            search()
          }"
        >
          Aplicar
        </div>
      </div>
    </b-popover> -->
  </div>
</template>

<script>
import CalendarPick from './CalendarPick.vue';

export default {
  name: 'CardKPICompanies',
  components: { 'calendar-pick': CalendarPick },
  props: {
    stats: Object,
    active: Number,
    setActive: Function,
    header: Array,
    date1: String,
    setDate1: Function,
    date2: String,
    setDate2: Function,
    search: Function,
  },
  data() {
    return {
      show: false,
      labels: {
        labelPrevDecade: 'Década anterior',
        labelPrevYear: 'Ano anterior',
        labelPrevMonth: 'Mês anterior',
        labelCurrentMonth: 'Mês atual',
        labelNextMonth: 'Próximo mês',
        labelNextYear: 'Próximo ano',
        labelNextDecade: 'Próxima década',
        labelToday: 'Hoje',
        labelSelected: 'Data selecionada',
        labelNoDateSelected: 'Sem data selecionada',
        labelCalendar: 'Calendário',
        labelNav: 'Navegação no calendário',
        labelHelp: 'Use as setas do teclado para navegar pelo calendário',
        labelResetButton: 'Limpar',
        resetButtonVariant: 'primary',
        labelTodayButton: 'Hoje',
        todayButtonVariant: 'primary',
        labelCloseButton: 'Fechar',
      },
    };
  },
};
</script>
