<template>
  <b-overlay
    :show="!loaded"
    style="min-height: 20vw;"
    opacity="0.5"
  >
    <div class="kpi-container">
      <div class="kpi-container">
        <div
          v-if="analytics"
          class="kpi-row"
        >
          <div
            class="kpi-card kpi-card-light"
            style="padding: 16px"
          >
            <b-row
              class="d-flex flex-row justify-content-between align-items-start"
            >
              <b-col
                md="12"
                xl="8"
                class="d-flex justify-content-start align-items-center mb-4"
              >
                <div
                  class="d-flex flex-column"
                  style="background-color: #F5F7F8; flex: 1; border-radius: 4px; padding: 12px 17px"
                >
                  <div style="">
                    Visualizações
                  </div>
                  <LineChart
                    :chart-data="analytics"
                    :options="options"
                    :height="300"
                    css-classes="line-chart"
                  />
                  <div class="d-flex flex-row flex-wrap justify-content-between align-items-center mt-2">
                    <div class="d-flex flex-row">
                      <div
                        style="font-size: 0.9286rem"
                        class="mr-2"
                      >
                        Tempo médio na plataforma
                      </div>
                      <div style="font-size: 0.9286rem; font-weight: 600">
                        {{ total_time.split(",")[0] }}m
                        {{ total_time.split(",")[1] }}s
                      </div>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="d-flex align-items-center justify-content-center mr-4">
                        <div
                          style="border: 1px solid #00455F; width: 7px;"
                          class="mr-2"
                        /><div
                          style="border: 1px solid #00455F; width: 7px;"
                          class="mr-2"
                        /><div
                          style="border: 1px solid #00455F; width: 7px;"
                          class="mr-2"
                        />{{ new Date().getFullYear() - 1 }}
                      </div>
                      <div class="d-flex align-items-center justify-content-center">
                        <div
                          style="border: 1px solid #00ADEE; width: 24px;"
                          class="mr-2"
                        />{{ new Date().getFullYear() }}
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="12"
                xl="4"

                style="min-height: 280px;  border-radius: 4px;"
              >
                <b-row>
                  <!--  <b-col
                    cols="12"
                    class="d-flex justify-content-end align-items-center flex-wrap"
                  >
                    <div class="buttons my-0 align-self-start">
                      <div
                        id="calendar3"
                        class="button sm outlined"
                      >
                        <b-icon icon="calendar3" />
                        {{ date1_generic }} -
                        {{ date2_generic }}
                      </div>
                    </div>
                  </b-col> -->
                  <b-col
                    cols="12"
                    class="d-flex justify-content-center align-items-center flex-wrap"
                  >
                    <div class="pt-4">
                      <div
                        class="align-self-start d-flex align-items-center justify-content-center"
                        :style="`height: 280px;
                          width: 280px;
                          border-radius: 50%;
                          background: conic-gradient(#00455F ${users * 100/ (users + facilitators)}%, #00ADEE ${users * 100 / (users + facilitators)}% ${(users + facilitators) * 100 / (users + facilitators) }%)`"
                      >
                        <div
                          style="background-color: white; border-radius: 50%; height: 214px; width: 214px"
                          class="d-flex align-items-center justify-content-center flex-column"
                        >
                          <div style="font-size: 48px; font-weight: 600; line-height: 48px">
                            {{ facilitators + users }}
                          </div>
                          <div
                            style="font-size: 14px; font-weight: 400"
                            class="text-dark-grey"
                          >
                            Utilizadores
                          </div>
                        </div>
                      </div>
                      <div
                        class="mt-4 d-flex flex-row flex-wrap"
                        style="justify-content: space-evenly"
                      >
                        <div class="d-flex align-items-baseline">
                          <div
                            style="width: 10px; height: 10px; background-color: #00455F"
                            class="mr-2"
                          />
                          <div>
                            <div style="font-weight: 600">
                              Registados
                            </div>
                            <div>{{ `${(users * 100 / (users + facilitators)).toFixed(2)}%` }}|{{ users }}</div>
                          </div>
                        </div>
                        <div class="d-flex align-items-baseline">
                          <div
                            style="width: 10px; height: 10px; background-color: #00ADEE"
                            class="mr-2"
                          />
                          <div>
                            <div style="font-weight: 600">
                              Facilitadores
                            </div>
                            <div>{{ `${(facilitators * 100 / (users + facilitators)).toFixed(2)}%` }}|{{ facilitators }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>

        <!--   <calendar-pick
          v-if="loaded"
          target="calendar3"
          :date1="date1_generic"
          :set-date1="(value) => {
            date1_generic = value
          }"
          :date2="date2_generic"
          :set-date2="(value) => {
            date2_generic = value
          }"
          :search="getReport"
        /> -->

        <card-kpi-companies
          :stats="stats"
          :active="active"
          :header="[
            {
              value: 1,
              label: 'Todas as Empresas'
            },
            {
              value: 2,
              label: 'Em Autodiagnóstico'
            },
            {
              value: 3,
              label: 'C/ Facilitador'
            }
          ]"
          :set-active="(value) => {active = value}"
          :date1="date1"
          :set-date1="(value) => {
            date1 = value
          }"
          :date2="date2"
          :set-date2="(value) => {
            date2 = value
          }"
          :search="search"
        />
        <card-kpi-region-sector
          :stats="stats_region_sector"
          :header="[
            {
              value: 1,
              label: 'Regiões'
            },
            {
              value: 2,
              label: 'Setores'
            },
          ]"
          :date1="date1_region_sector"
          :set-date1="(value) => {
            date1_region_sector = value
          }"
          :date2="date2_region_sector"
          :set-date2="(value) => {
            date2_region_sector = value
          }"
          :search="search"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { LineChart } from 'vue-chart-3';
import { get } from '../../../services/api';
import CardKPICompanies from '../CardKPICompanies.vue';
import CardKPIRegionSector from '../CardKPIRegionSector.vue';
import CalendarPick from '../CalendarPick.vue';

export default {
  name: 'DashboardKPIAdmin',
  components: {
    'card-kpi-companies': CardKPICompanies, 'card-kpi-region-sector': CardKPIRegionSector, LineChart, 'calendar-pick': CalendarPick,
  },
  data() {
    return {
      active: null,
      quiz_facilitator_stats: null,
      stats: null,
      stats_region_sector: null,
      total_time: null,
      date1: new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      date2: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      date1_region_sector: new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      date2_region_sector: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),

      date1_generic: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate(),
      ).toLocaleDateString(),
      date2_generic: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      facilitators: null,
      users: null,
      analytics: null,
      options: null,
      loaded: false,
    };
  },
  watch: {
    active: {
      handler() {
        this.search();
      },
    },
  },

  async created() {
    this.active = 1;

    await get('user').then((response) => {
      this.users = response.data.users.filter((el) => el.fid === null || el.state != 2).length;
      this.facilitators = response.data.users.filter((el) => el.fid !== null && el.state == 2).length;
    });

    await this.getReport();
    this.loaded = true;
  },

  methods: {
    async getReport() {
      await get(`analytics?start_date=${this.date1_generic.split('/').reverse().join('-')}&end_date=${this.date2_generic.split('/').reverse().join('-')}`)
        .then((response) => {
          this.options = {
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            stacked: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title(tooltipItem) {
                    return 'Nr. de visualizações';
                  },
                  label(tooltipItem, data) {
                    if (tooltipItem.datasetIndex === 0) {
                      return `${new Date().getFullYear()}: ${tooltipItem.formattedValue}`;
                    }
                    return `${new Date().getFullYear() - 1}: ${tooltipItem.formattedValue}`;

                    /* const datasetLabel = this.analytics.datasets[tooltipItem.datasetIndex].label || 'Other';
                    const label = data.labels[tooltipItem.index]; */
                  },
                  /*  label(tooltipItem) {
                    return tooltipItem.formattedValue;
                  }, */
                },
              },
            },

            scales: {
              y1: {
                display: false,
                // grid line settings
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
              },
              xAxes: {
                ticks: {
                // Include a dollar sign in the ticks
                  callback(value, index, values) {
                    const months = {
                      0: 'jan.',
                      1: 'fev.',
                      2: 'mar.',
                      3: 'abr.',
                      4: 'mai.',
                      5: 'jun.',
                      6: 'jul.',
                      7: 'ago.',
                      8: 'set.',
                      9: 'out.',
                      10: 'nov.',
                      11: 'dez.',
                    };
                    return months[index];
                  },
                },
                grid: {
                  display: false,

                },
              },
            },
          };
          const result = response.data;

          const { length } = result.datasets[0].data;
          if (length < 12) {
            const diff = 12 - length;
            for (let i = 0; i < diff; i++) {
              result.datasets[0].data.push(null);
              result.datasets[1].data.push(null);
              result.labels.push(`//${length + i}`);
            }
          }

          this.total_time = response.data.total_time;

          result.datasets[0].backgroundColor = '#00ADEE';
          result.datasets[0].borderColor = '#00ADEE';
          result.datasets[0].tension = 0.1;
          result.datasets[0].borderWidth = 2;
          result.datasets[0].pointBorderWidth = 1;
          result.datasets[0].pointBackgroundColor = '#00ADEE';
          result.datasets[0].pointBorderColor = '#00ADEE';
          result.datasets[0].pointHoverBackgroundColor = '#00ADEE';
          result.datasets[0].yAxisID = 'y0';
          result.datasets[1].borderDash = [10];
          result.datasets[1].tension = 0.1;
          result.datasets[1].borderWidth = 2;
          result.datasets[1].borderColor = '#00455F';
          result.datasets[1].pointBorderWidth = 1;
          result.datasets[1].pointBackgroundColor = '#00455F';
          result.datasets[1].pointBorderColor = '#00455F';
          result.datasets[1].pointHoverBackgroundColor = '#00455F';
          result.datasets[1].yAxisID = 'y1';

          this.analytics = result;
        });
    },
    async search() {
      await get(`quiz_stats?from=
        ${this.date1.split('/').reverse().join('-')}&to=${this.date2.split('/').reverse().join('-')}
      `)
        .then((response) => {
          this.quiz_facilitator_stats = response.data;
          if (this.active === 1) {
            this.stats = {
              big: this.quiz_facilitator_stats.big,
              pme: this.quiz_facilitator_stats.pme,
              micro: this.quiz_facilitator_stats.micro,
              quizzes: this.quiz_facilitator_stats.quizzes,
              submitted: this.quiz_facilitator_stats.submitted,
              media: this.quiz_facilitator_stats.media,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          } else if (this.active === 2) {
            this.stats = {
              big: this.quiz_facilitator_stats.big_wo_facilitator,
              pme: this.quiz_facilitator_stats.pme_wo_facilitator,
              micro: this.quiz_facilitator_stats.micro_wo_facilitator,
              quizzes: this.quiz_facilitator_stats.quizzes_wo_facilitator,
              submitted: this.quiz_facilitator_stats.submitted_wo_facilitator,
              media: this.quiz_facilitator_stats.media_wo_facilitator,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          } else if (this.active === 3) {
            this.stats = {
              big: this.quiz_facilitator_stats.big_w_facilitator,
              pme: this.quiz_facilitator_stats.pme_w_facilitator,
              micro: this.quiz_facilitator_stats.micro_w_facilitator,
              quizzes: this.quiz_facilitator_stats.quizzes_w_facilitator,
              submitted: this.quiz_facilitator_stats.submitted_w_facilitator,
              media: this.quiz_facilitator_stats.media_w_facilitator,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          }
        });
      await get(`quiz_region_sector_stats?from=
        ${this.date1_region_sector.split('/').reverse().join('-')}&to=${this.date2_region_sector.split('/').reverse().join('-')}
      `).then((response) => {
        this.stats_region_sector = response.data;
      });
    },
  },
};
</script>
